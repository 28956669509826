<!-- 
CURRENTLY UNDER DEVELOPMENT.  This will eventually be a searchable table,
displaying all the available people within the database.  The current implementation
below is extremely limited and not a representation of the development direction of
this feature in the future. 

Update:  This page is being considered for deletion now that we can pass data between pages.
-->


<template>
    <v-container v-if="$store.state.user">

        <div class="mt-5">
            <v-data-table
                :headers="headers"
                :items="people"
                item-key="index"
                class="elevation-1"
                :search="search"
                :custom-filter="filterOnlyCapsText"
            >
                <template v-slot:top>
                <v-text-field
                    v-model="search"
                    label="Search (UPPER CASE ONLY)"
                    class="mx-4"
                ></v-text-field>
                </template>


            </v-data-table>
        </div>

    </v-container>
</template>

<script>
import PeopleService from '@/services/PeopleService'

export default {
    data () {
        return {
            search: '',
            calories: '',
            people: [],
        }
    },
    computed: {
    headers () {
        return [
                {
                    text: 'First Name',
                    align: 'start',
                    sortable: false,
                    value: this.people.data.rows.first_name 
                },
                { text: 'Last Name', value: 'last_name' },
                { text: 'Address', value: 'street_address_1'},
                { text: 'City' },
                { text: 'State' },
                { text: 'Zip'},
            ]
        },
    },
    async mounted() {
        this.people = await PeopleService.getAllPeople()
    },

    methods: {
        // eslint-disable-next-line no-unused-vars
        filterOnlyCapsText (value, search, item) {
            return value != null &&
            search != null &&
            typeof value === 'string' &&
            value.toString().toLocaleUpperCase().indexOf(search) !== -1
        },
    },
}
</script>